import {
  EyeFilled,
  UserOutlined,
  LogoutOutlined,
  UserSwitchOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu, Avatar, Tooltip } from "antd";
import MenuItems from "../../../assets/images/menu-items";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../../../redux/auth/selector";
import { userTypes } from "../../../redux/educator/actions";
import { getLoggedUser, types } from "../../../redux/auth/actions";
import "../../../assets/styles/sidebar.scss";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: () => void
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
  } as MenuItem;
}

const pathToKeyMap: Record<string, string> = {
  "/educator/home": "educatorHome",
  "/educator/benchmark": "educatorBenchmark",
  "/educator/dashboard/module": "moduleDashboard",
  "/educator/dashboard/course": "courseDashboard",
  "/educator/dashboard/lesson": "lessonDashboard",
  "/educator/dashboard/user": "usersDashboard",
  "/educator/dashboard": "educatorDashboard",
  "/educator/module": "viewModule",
  "/educator/course": "viewCourse",
  "/educator/lesson": "viewCourseLesson",
  "/educator/quiz": "viewQuiz",
  "/educator/alert": "viewAlert",
  "/educator/quizzie": "viewQuizzie",
  "/educator/module/create": "module",
  "/educator/course/create": "course",
  "/educator/lesson/create": "lesson",
  "/educator/quiz/create": "quiz",
  "/educator/alert/create": "alert",
  "/educator/quizzie/create": "quizzie",
  "/educator/department": "departments",
  "/educator/position": "positions",
  "/educator/user": "users",
  "/educator/sign-up": "user",
};

const EducatorSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const [siderWidth, setSiderWidth] = useState(360);
  const baseItems = useMemo(
    () => [
      getItem("Home", "educatorHome", <MenuItems.Home />, undefined, () =>
        navigate("/educator/home")
      ),
      getItem(
        "Benchmark",
        "educatorBenchmark",
        <LineChartOutlined style={{ fontSize: "18px", color: "#8199F8" }} />,
        undefined,
        () => navigate("/educator/benchmark")
      ),
      getItem("Dashboard", "educatorDashboard", <MenuItems.Dashboard />, [
        getItem(
          "Module",
          "moduleDashboard",
          <MenuItems.Module />,
          undefined,
          () => navigate("/educator/dashboard/module")
        ),
        getItem(
          "Course",
          "courseDashboard",
          <MenuItems.Course />,
          undefined,
          () => navigate("/educator/dashboard/course")
        ),
        getItem(
          "Lesson",
          "lessonDashboard",
          <MenuItems.Lesson />,
          undefined,
          () => navigate("/educator/dashboard/lesson")
        ),
        getItem("Users", "usersDashboard", <MenuItems.Users />, undefined, () =>
          navigate("/educator/dashboard/user")
        ),
      ]),
      getItem(
        "View",
        "viewSubMenu",
        <EyeFilled className="submenu-icon-color" style={{ fontSize: 18 }} />,
        [
          getItem("Module", "viewModule", <MenuItems.Module />, undefined, () =>
            navigate("/educator/module")
          ),
          getItem("Course", "viewCourse", <MenuItems.Course />, undefined, () =>
            navigate("/educator/course")
          ),
          getItem(
            "Lesson",
            "viewCourseLesson",
            <MenuItems.Lesson />,
            undefined,
            () => navigate("/educator/lesson")
          ),
          getItem("Quiz", "viewQuiz", <MenuItems.Quiz />, undefined, () =>
            navigate("/educator/quiz")
          ),
          getItem("Alert", "viewAlert", <MenuItems.Alert />, undefined, () =>
            navigate("/educator/alert")
          ),
          getItem(
            "Quizzie",
            "viewQuizzie",
            <MenuItems.Quizzie />,
            undefined,
            () => navigate("/educator/quizzie")
          ),
        ]
      ),

      getItem(
        "Create",
        "createSubMenu",
        <MenuItems.Create className="submenu-icon-color" />,
        [
          getItem("Module", "module", <MenuItems.Module />, undefined, () =>
            navigate("/educator/module/create")
          ),
          getItem("Course", "course", <MenuItems.Course />, undefined, () =>
            navigate("/educator/course/create")
          ),
          getItem("Lesson", "lesson", <MenuItems.Lesson />, undefined, () =>
            navigate("/educator/lesson/create")
          ),
          getItem("Quiz", "quiz", <MenuItems.Quiz />, undefined, () =>
            navigate("/educator/quiz/create")
          ),
          getItem("Alert", "alert", <MenuItems.Alert />, undefined, () =>
            navigate("/educator/alert/create")
          ),
          getItem("Quizzie", "quizzie", <MenuItems.Quizzie />, undefined, () =>
            navigate("/educator/quizzie/create")
          ),
        ]
      ),
      getItem(
        "Company Structure",
        "companyStructureSubMenu",
        <MenuItems.CompanyStructure />,
        [
          getItem(
            "Departments",
            "departments",
            <MenuItems.Department />,
            undefined,
            () => navigate("/educator/department")
          ),
          getItem(
            "Positions",
            "positions",
            <MenuItems.Position />,
            undefined,
            () => navigate("/educator/position")
          ),
          getItem("Users", "users", <MenuItems.Users />, undefined, () =>
            navigate("/educator/user")
          ),
          getItem("Add user", "user", <MenuItems.User />, undefined, () =>
            navigate("/educator/sign-up")
          ),
        ]
      ),
      getItem(
        "Logout",
        "logout",
        <LogoutOutlined style={{ fontSize: 18 }} />,
        undefined,
        () =>
          dispatch({ type: userTypes.LOGOUT_EDUCATOR, payload: { navigate } })
      ),
    ],
    [navigate, dispatch]
  );
  const [items, setItems] = useState(baseItems);

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  useEffect(() => {
    if (
      user &&
      user.role.length > 1 &&
      !items.find((item) => item?.key === "switch")
    ) {
      const newItem = getItem(
        "Switch to User",
        "switch",
        <UserSwitchOutlined style={{ fontSize: 18 }} />,
        undefined,
        () => dispatch({ type: types.SWITCH_ROLE, payload: { navigate } })
      );
      const newItems = [...items];
      newItems.splice(newItems.length - 1, 0, newItem);
      setItems(newItems);
    }
  }, [user, navigate, dispatch, items]);

  useEffect(() => {
    setSelectedKey(location.pathname);

    const key = pathToKeyMap[location.pathname];
    if (
      ["course", "lesson", "alert", "module", "quiz", "quizzie"].includes(key)
    ) {
      setOpenKeys(["createSubMenu"]);
    } else if (["departments", "positions", "users", "user"].includes(key)) {
      setOpenKeys(["companyStructureSubMenu"]);
    } else if (
      [
        "viewModule",
        "viewCourse",
        "viewCourseLesson",
        "viewQuiz",
        "viewQuizzie",
        "viewAlert",
      ].includes(key)
    ) {
      setOpenKeys(["viewSubMenu"]);
    } else if (
      [
        "moduleDashboard",
        "courseDashboard",
        "lessonDashboard",
        "usersDashboard",
      ].includes(key)
    ) {
      setOpenKeys(["educatorDashboard"]);
    }
  }, [location.pathname, selectedKey]);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 1024) {
        setSiderWidth(200);
        return;
      }
      if (screenWidth < 1200) {
        setSiderWidth(250);
        return;
      }
      if (screenWidth < 1400) {
        setSiderWidth(260);
        return;
      }
      if (screenWidth < 1500) {
        setSiderWidth(280);
        return;
      }
      if (screenWidth < 1600) {
        setSiderWidth(320);
        return;
      }
      if (screenWidth < 1800) {
        setSiderWidth(360);
        return;
      }
    };

    window.addEventListener("resize", handleResize);

    // Set the initial width
    handleResize();

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
      theme="light"
      width={siderWidth}
    >
      <div className="flex flex-col gap-7">
        <div className="h-20 bg-primary-850">
          <div className="px-5 py-6 flex justify-items-center items-center h-full gap-3">
            <Tooltip title="Company settings">
              <Avatar
                size={48}
                icon={<UserOutlined />}
                src={"../company-logo.webp"}
              />
            </Tooltip>
            <div className="text-white">CyberEdukacija</div>
          </div>
        </div>

        <div className="px-5 flex justify-items-center items-center h-full  gap-3">
          <Tooltip title="Profile settings">
            <Avatar
              size={48}
              src={
                user?.profilePictureKey &&
                process.env.REACT_APP_CLOUDFRONT_KEY +
                  "/" +
                  user?.profilePictureKey
              }
              icon={<UserOutlined />}
              className="cursor-pointer"
              onClick={() => navigate("/settings/" + user?.id)}
            />
          </Tooltip>
          <div>
            <div>{user?.name ?? user?.email}</div>
            <div className="text-primary-600">Educator</div>
          </div>
        </div>
        <Menu
          theme="light"
          mode="inline"
          items={items}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          selectedKeys={[pathToKeyMap[location.pathname]]}
        />
      </div>
    </Sider>
  );
};

export default EducatorSidebar;
