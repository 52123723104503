import { io, Socket } from "socket.io-client";
import StorageService from "./storageService";

let socket: Socket | undefined;

export const initiateSocketConnection = () => {
  const token = StorageService.getStringItem("accessToken");
  socket = io(process.env.REACT_APP_SOCKET_BASE_URL, {
    extraHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

interface UploadProgressData {
  progress: number;
}

export const subscribeToUploadProgress = (
  callback: (data: UploadProgressData) => void
): void => {
  if (!socket) return;
  socket.on("uploadProgress", (data: UploadProgressData) => {
    callback(data);
  });
};
