export const lessonTypes = {
  ADD_LESSON: "ADD_LESSON",
  GET_LESSON: "GET_LESSON",
  GET_LESSON_BY_ID: "GET_LESSON_BY_ID",
  DELETE_LESSON: "DELETE_LESSON",
  SET_LESSON: "SET_LESSON",
  SET_LESSON_BY_ID: "SET_LESSON_BY_ID",
  EDIT_LESSON: "EDIT_LESSON",
};

export const addLesson = (payload: any) => ({
  type: lessonTypes.ADD_LESSON,
  payload,
});

export const getLesson = () => ({
  type: lessonTypes.GET_LESSON,
});

export const setLesson = (payload: any) => ({
  type: lessonTypes.SET_LESSON,
  payload,
});

export const getLessonById = (id: any) => ({
  type: lessonTypes.GET_LESSON_BY_ID,
  payload: id,
});

export const setLessonById = (payload: any) => ({
  type: lessonTypes.SET_LESSON_BY_ID,
  payload,
});

export const deleteLesson = (payload: any) => ({
  type: lessonTypes.DELETE_LESSON,
  payload,
});

export const editLesson = (payload: any) => ({
  type: lessonTypes.EDIT_LESSON,
  payload,
});
