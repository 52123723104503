import {
  Input,
  Button,
  DatePicker,
  Select,
  Avatar,
  Upload,
  Form,
  Row,
  Col,
  Modal,
} from "antd";
import {
  UserOutlined,
  ArrowLeftOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useParams, useNavigate } from "react-router-dom";
import logoImage from "../../../assets/images/company-logo.webp";
import { ReactComponent as Camera } from "../../../assets/images/camera.svg";
import { ReactComponent as UploadIcon } from "../../../assets/images/upload.svg";

const { Option } = Select;
const cloudFrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const Settings = (props: any) => {
  const {
    user,
    actions: { getUserById, editUser },
  } = props;
  const [form] = Form.useForm();
  const [isChanged, setIsChanged] = useState(false);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getUserById({ id });
  }, []);

  useEffect(() => {
    if (!user) return;
    setAvatarUrl(
      user.profilePictureKey ? `${cloudFrontKey}/${user.profilePictureKey}` : ""
    );
    form.setFieldsValue({
      name: user.name,
      email: user.email,
      dateOfBirth: dayjs(user.dateOfBirth),
      language: user.language,
    });
  }, [user, form]);

  const handleFormChange = () => {
    const fieldsChanged = form.isFieldsTouched();
    setIsChanged(fieldsChanged);
  };

  const handleSaveChanges = () => {
    form.validateFields().then((values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("dateOfBirth", values.dateOfBirth.format("YYYY-MM-DD"));
      formData.append("language", values.language);
      if (profilePicture) {
        formData.append("profilePicture", profilePicture);
      }
      editUser({ id, user: formData });
    });
  };

  const handleUploadChange = (info: any) => {
    const file = info.file.originFileObj || info.file;

    if (file) {
      setProfilePicture(file);
      try {
        const imageUrl = URL.createObjectURL(file);
        setAvatarUrl(imageUrl);
      } catch (error) {
        console.error("Error creating object URL: ", error);
      }

      setIsChanged(true);
    } else {
      console.error("No valid file provided for upload.");
    }
  };

  const beforeUpload = (file: File) => {
    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Row className="bg-neutral-100">
      <Col span={12} className="flex items-center justify-center relative">
        <Button
          icon={<ArrowLeftOutlined className="text-neutral-300 mr-2" />}
          className="bg-transparent border-none shadow-none absolute top-16 left-16 text-neutral-600 font-semibold text-sm pt-2"
          onClick={() => navigate(-1)}
        >
          My Settings
        </Button>
        {user && (
          <div className="flex flex-col w-[350px]">
            <div className="flex justify-center mb-4">
              <div className="relative bg-white p-2 rounded-[100px] shadow-sm">
                <Avatar size={170} icon={<UserOutlined />} src={avatarUrl} />
                <Button
                  icon={<Camera />}
                  className="absolute bottom-4 right-1 text-primary-600 border-none shadow-md p-0 min-w-10 h-10 rounded-3xl"
                  onClick={showModal}
                />
              </div>
            </div>

            <Form
              form={form}
              layout="vertical"
              onValuesChange={handleFormChange}
              initialValues={{
                name: user.name,
                email: user.email,
                dateOfBirth: dayjs(user.dateOfBirth),
                language: user.language,
              }}
            >
              <Form.Item
                name="name"
                label="Name"
                labelCol={{
                  className: "text-neutral-600 font-semibold text-sm",
                }}
              >
                <Input placeholder="Name" size="large" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                labelCol={{
                  className: "text-neutral-600 font-semibold text-sm",
                }}
              >
                <Input placeholder="Email" size="large" />
              </Form.Item>

              <Form.Item
                name="dateOfBirth"
                label="Date of Birth"
                labelCol={{
                  className: "text-neutral-600 font-semibold text-sm",
                }}
              >
                <DatePicker
                  placeholder="Date of Birth"
                  size="large"
                  className="w-full h-[50px]"
                />
              </Form.Item>

              <Form.Item
                name="language"
                label="Language"
                labelCol={{
                  className: "text-neutral-600 font-semibold text-sm",
                }}
              >
                <Select size="large" className="w-full min-h-[50px]">
                  <Option value="English">English</Option>
                  <Option value="Bosnian">Bosnian</Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  block
                  size="large"
                  className="bg-primary-600 h-[50px]"
                  onClick={handleSaveChanges}
                  disabled={!isChanged}
                >
                  Save Changes
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}

        <Modal
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          className="max-w-[350px]"
          footer={
            <div className="flex gap-4 ">
              <Button key="cancel" onClick={handleCancel} className="w-1/2">
                Close
              </Button>
              <Upload
                beforeUpload={beforeUpload}
                onChange={handleUploadChange}
                showUploadList={false}
                className="w-1/2 "
              >
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleOk}
                  className="w-[142px]"
                >
                  Browse Files
                </Button>
              </Upload>
            </div>
          }
        >
          <div className="flex flex-col items-center justify-cente">
            <UploadIcon />
            <p className="text-neutral-600 text-base font-medium mt-6">
              Upload a cover image
            </p>
            <p className="text-xs text-neutral-500 font-normal mb-5">
              PNG, JPG, GIF up to 5MB
            </p>
          </div>
        </Modal>
      </Col>
      <Col
        span={12}
        className="bg-white h-screen flex justify-center items-center"
      >
        <img src={logoImage} alt="auth-image" />
      </Col>
    </Row>
  );
};

export default Settings;
