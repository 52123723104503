import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const getCoursesAnalytics = async (type: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.educatorAnalytics.replace(":type", type))
  );

export const getCourseAnalyticsById = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.courseAnalytics.replace(":id", id))
  );

export const getModuleAnalyticsById = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.moduleAnalytics.replace(":id", id))
  );

export const getBenchmarkAnalytics = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.webAnalytics));

export const getLessonAnalyticsById = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.lessonAnalytics.replace(":id", id))
  );
