import {
  SetLoginPayload,
  SetRegisterPayload,
  SetResetPasswordPayload,
  SetSendResetPasswordPayload,
  AuthenticationPayload,
  SetChooseRolePayload,
} from "../../../utils";

export const types = {
  LOGIN_ACTION: "LOGIN_ACTION",
  LOGIN_ACTION_SUCCESS: "LOGIN_ACTION_SUCCESS",
  REGISTER_ACTION: "REGISTER_ACTION",
  REGISTER_ACTION_SUCCESS: "REGISTER_ACTION_SUCCESS",
  RESET_PASSWORD_ACTION: "RESET_PASSWORD_ACTION",
  RESET_PASSWORD_ACTION_SUCCESS: "RESET_PASSWORD_ACTION_SUCCESS",
  SEND_RESET_PASSWORD_EMAIL_ACTION: "SEND_RESET_PASSWORD_EMAIL_ACTION",
  SEND_RESET_PASSWORD_EMAIL_ACTION_SUCCESS:
    "SEND_RESET_PASSWORD_EMAIL_ACTION_SUCCESS",
  CHOOSE_ROLE_ACTION: "CHOOSE_ROLE_ACTION",
  GET_LOGGED_USER: "GET_LOGGED_USER",
  SET_LOGGED_USER: "SET_LOGGED_USER",
  SWITCH_ROLE: "SWITCH_ROLE",
  UPLOAD_INVITE_USERS: "UPLOAD_INVITE_USERS",
};

export const login = (payload: SetLoginPayload) => ({
  type: types.LOGIN_ACTION,
  payload,
});

export const loginSuccess = (payload: AuthenticationPayload) => ({
  type: types.LOGIN_ACTION_SUCCESS,
  payload,
});

export const chooseRole = (payload: SetChooseRolePayload) => ({
  type: types.CHOOSE_ROLE_ACTION,
  payload,
});

export const register = (payload: {
  user: SetRegisterPayload;
  navigate: any;
}) => ({
  type: types.REGISTER_ACTION,
  payload,
});

export const resetPassword = (payload: SetResetPasswordPayload) => ({
  type: types.RESET_PASSWORD_ACTION,
  payload,
});

export const sendResetPassword = (payload: SetSendResetPasswordPayload) => ({
  type: types.SEND_RESET_PASSWORD_EMAIL_ACTION,
  payload,
});

export const getLoggedUser = () => ({
  type: types.GET_LOGGED_USER,
});

export const setLoggedUser = (payload: any) => ({
  type: types.SET_LOGGED_USER,
  payload,
});

export const switchRole = () => ({
  type: types.SWITCH_ROLE,
});

export const uploadInviteUsers = (payload: any) => ({
  type: types.UPLOAD_INVITE_USERS,
  payload,
});
