import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  Typography,
  Modal,
  Upload,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import { UploadOutlined } from "@ant-design/icons";
import showNotification from "../../../../services/notificationService";

const { Text } = Typography;

const SignUp = (props: any) => {
  const {
    departments,
    positions,
    actions: { signUp, getDepartment, getPosition, uploadInviteUsers },
  } = props;

  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [isUploadValid, setIsUploadValid] = useState(false);
  const [cyberDepartmentExists, setCyberDepartmentExists] = useState(false);

  const roles = ["USER", "EDUCATOR"];

  useEffect(() => {
    getDepartment();
    getPosition();
  }, []);

  useEffect(() => {
    const cyberDepartment = departments.find(
      (dept: any) => dept.name.toLowerCase() === "cyber"
    );
    setCyberDepartmentExists(!!cyberDepartment);
    setIsUploadValid(fileList.length > 0 && !!cyberDepartment);
  }, [fileList, departments]);

  const onFinishSignUp = ({
    email,
    departmentId,
    positionId,
    role,
  }: {
    email: string;
    departmentId: string | null;
    positionId: string | null;
    role: string;
  }) => {
    if (!email || !role)
      showNotification("error", "Please fill in all required fields.");
    signUp({
      email,
      departmentId: departmentId || null,
      positionId: positionId || null,
      role,
    });
  };

  const handleUpload = () => {
    if (fileList.length === 0) {
      showNotification("error", "Please select a JSON file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("file", fileList[0].originFileObj);

    const cyberDepartment = departments.find(
      (dept: any) => dept.name.toLowerCase() === "cyber"
    );

    if (cyberDepartment) {
      formData.append("departmentId", cyberDepartment.id);
    } else {
      showNotification("error", "Department 'cyber' not found.");
      return;
    }

    uploadInviteUsers(formData);

    setIsModalVisible(false);
    setFileList([]);
  };

  const handleFileChange = ({ fileList }: { fileList: any[] }) => {
    setFileList(fileList);
  };

  return (
    <Row className="h-full pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo
          title="Invite a User"
          text="Enter the information of the user you want to invite to the application"
        />
      </Col>
      <Col
        lg={16}
        className="h-full flex flex-col justify-between max-w-[600px]"
      >
        <Form
          form={form}
          name="basic"
          onFinish={onFinishSignUp}
          autoComplete="off"
          size="large"
        >
          <div className="w-full mb-7">
            <Text className="text-neutral-700 text-lg font-semibold">
              User Information
            </Text>
          </div>

          <Text className="text-neutral-600 text-sm font-semibold">
            Email Address
          </Text>

          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
            className="mt-2"
          >
            <Input
              type="email"
              autoComplete="off"
              name="email"
              placeholder="Input Email Address"
            />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            User role
          </Text>

          <Form.Item
            name="role"
            rules={[{ required: true, message: "Please select role!" }]}
            className="mt-2"
          >
            <Select placeholder="Select Role" className="min-h-[50px]">
              {roles.map((role) => (
                <Select.Option key={role} value={role}>
                  {role}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Department (Optional)
          </Text>

          <Form.Item
            name="departmentId"
            rules={[{ required: false }]}
            className="mt-2"
          >
            <Select
              placeholder="Select Department"
              allowClear
              className="min-h-[50px]"
            >
              {departments.map((dept: any) => (
                <Select.Option key={dept.id} value={dept.id}>
                  {dept.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Position (Optional)
          </Text>

          <Form.Item
            name="positionId"
            rules={[{ required: false }]}
            className="mt-2"
          >
            <Select
              placeholder="Select Position"
              allowClear
              className="min-h-[50px]"
            >
              {positions.map((pos: any) => (
                <Select.Option key={pos.id} value={pos.id}>
                  {pos.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <div className="w-full flex justify-end gap-4">
              <Tooltip
                title={
                  !cyberDepartmentExists
                    ? "Department 'cyber' not found"
                    : "Please select a JSON file to upload"
                }
              >
                <Button
                  type="default"
                  className="w-1/2 h-[50px]"
                  onClick={() => setIsModalVisible(true)}
                  disabled={!cyberDepartmentExists}
                >
                  Upload JSON
                </Button>
              </Tooltip>
              <Button
                type="primary"
                htmlType="submit"
                className="w-1/2 bg-primary-600 h-[50px]"
              >
                Send Invitation
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Col>

      <Modal
        title="Invite Users to Department Cyber"
        open={isModalVisible}
        onOk={handleUpload}
        onCancel={() => setIsModalVisible(false)}
        okText="Upload"
        cancelText="Cancel"
        okButtonProps={{ disabled: !isUploadValid }}
      >
        <Upload
          accept=".json"
          beforeUpload={() => false}
          fileList={fileList}
          onChange={handleFileChange}
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Select JSON File</Button>
        </Upload>
      </Modal>
    </Row>
  );
};

export default SignUp;
