import {
  Form,
  Input,
  Upload,
  Button,
  Typography,
  Progress,
  Modal,
  UploadFile,
} from "antd";
import { ReactComponent as UploadIcon } from "../../../../assets/images/upload.svg";
import { ReactComponent as UploadVideoIcon } from "../../../../assets/images/upload-video.svg";
import { ReactComponent as UploadPDFIcon } from "../../../../assets/images/upload-pdf.svg";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  initiateSocketConnection,
  subscribeToUploadProgress,
  disconnectSocket,
} from "../../../../services/socketService";
import showNotification from "../../../../services/notificationService";

const { Text } = Typography;
const { TextArea } = Input;

const CreateLessonScreen = (props: any) => {
  const {
    actions: { addLesson, setInitialSteps },
  } = props;

  const [fileList, setFileList] = useState<any>([]);
  const [videoFileList, setVideoFileList] = useState<any>([]);
  const [pdfFileList, setPdfFileList] = useState<any>([]);
  const [progress, setProgress] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    setInitialSteps(2);
  }, []);

  const handleSubmit = ({
    title,
    description,
    points,
  }: {
    title: string;
    description: string;
    points: number;
  }) => {
    if (fileList.length === 0) {
      showNotification("error", "Please upload a cover image");
      return;
    }
    if (videoFileList.length === 0 && pdfFileList.length === 0) {
      showNotification("error", "Please upload a video or a PDF");
      return;
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("points", points.toString());
    if (fileList.length > 0) {
      formData.append("coverImage", fileList[0].originFileObj as File);
    }
    if (pdfFileList.length > 0) {
      formData.append("file", pdfFileList[0].originFileObj as File);
    }
    if (videoFileList.length > 0) {
      formData.append("video", videoFileList[0].originFileObj as File);

      initiateSocketConnection();
      subscribeToUploadProgress((data) => {
        setProgress(Math.round(data.progress * 100));
      });

      setIsModalVisible(true);
      addLesson({ lesson: formData, navigate });
      return () => {
        disconnectSocket();
        setIsModalVisible(false);
      };
    } else {
      addLesson({ lesson: formData, navigate });
    }
  };

  const handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };

  const handleVideoChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setVideoFileList(fileList);
  };

  const handlePdfChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setPdfFileList(fileList);
  };

  const handleRemoveVideo = () => {
    setVideoFileList([]);
  };

  const handleCancelModal = () => {
    setIsModalVisible(false);
    setProgress(0);
  };

  return (
    <div>
      <Form onFinish={handleSubmit} className="flex flex-row pb-10 gap-10">
        <div className="flex-1">
          <Text className="text-neutral-600 text-sm font-semibold">Title</Text>
          <Form.Item
            name="title"
            className="mt-2"
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input placeholder="Type the title here" />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Description
          </Text>
          <Form.Item
            name="description"
            className="mt-2"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <TextArea placeholder="Add description here" rows={10} />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">Points</Text>
          <Form.Item
            name="points"
            className="mt-2"
            rules={[{ required: true, message: "Please input the points!" }]}
          >
            <Input type="number" placeholder="Points" />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Cover Image
          </Text>

          <Upload
            name="coverImage"
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => false}
            accept="image/png, image/jpeg, image/gif"
            className="bg-white rounded-md h-[310px] mt-2"
            fileList={fileList}
            onChange={handleChange}
          >
            {fileList.length === 0 && (
              <div className="flex flex-col items-center justify-center">
                <UploadIcon />
                <p className="text-neutral-600 text-base font-medium">
                  Upload a cover image
                </p>
                <p className="text-xs text-neutral-500 font-normal">
                  PNG, JPG, GIF up to 5MB
                </p>
              </div>
            )}
          </Upload>
        </div>
        <div className="flex-1 flex flex-col justify-between mt-[100px]">
          <div>
            <Text className="text-neutral-600 text-sm font-semibold">
              Upload Video
            </Text>
            <Upload
              name="video"
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
              accept="video/mp4"
              className="bg-white rounded-md h-[310px] mt-2"
              fileList={videoFileList}
              onChange={handleVideoChange}
              showUploadList={false}
            >
              {videoFileList.length === 0 ? (
                <div className="flex flex-col items-center justify-center">
                  <UploadVideoIcon />
                  <p className="text-neutral-600 text-base font-medium">
                    Upload a video
                  </p>
                  <p className="text-xs text-neutral-500 font-normal">
                    MP4 up to 50MB
                  </p>
                </div>
              ) : (
                <div className="relative flex justify-center items-center w-full h-full">
                  <video
                    src={URL.createObjectURL(
                      videoFileList[0].originFileObj as Blob
                    )}
                    controls
                    className="w-full h-full object-cover"
                  />
                  <Button
                    onClick={handleRemoveVideo}
                    className="absolute top-2 right-2 bg-red-500 text-white"
                  >
                    Remove
                  </Button>
                </div>
              )}
            </Upload>
          </div>
          <div>
            <Text className="text-neutral-600 text-sm font-semibold">
              Upload PDF
            </Text>
            <Upload
              name="pdf"
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
              accept="application/pdf"
              className="bg-white rounded-md h-[310px] mt-2"
              fileList={pdfFileList}
              onChange={handlePdfChange}
              iconRender={() => (
                <div className="w-full h-full flex items-center justify-center ">
                  <UploadPDFIcon />
                </div>
              )}
            >
              {pdfFileList.length === 0 && (
                <div className="flex flex-col items-center justify-center">
                  <UploadPDFIcon />
                  <p className="text-neutral-600 text-base font-medium">
                    Upload a PDF
                  </p>
                  <p className="text-xs text-neutral-500 font-normal">
                    PDF up to 10MB
                  </p>
                </div>
              )}
            </Upload>
          </div>
          <div className="w-100 flex flex-row justify-end">
            <Form.Item className="w-2/4 mb-0">
              <Button
                type="primary"
                htmlType="submit"
                className="w-full bg-primary-600 h-[50px] font-semibold text-base mb-0"
              >
                Continue
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>

      <Modal
        title="Upload Progress"
        open={isModalVisible}
        footer={null}
        closable={true}
        onCancel={handleCancelModal}
      >
        <Progress percent={progress} />
      </Modal>
    </div>
  );
};

export default CreateLessonScreen;
