import { Card, Divider, Typography } from "antd";
import { DownloadOutlined, EyeFilled } from "@ant-design/icons";
import { ReactComponent as PDFIcon } from "../../assets/images/upload-pdf.svg";

const { Text, Link } = Typography;

const DocumentCard = ({
  fileKey,
  onOpen,
}: {
  fileKey: string;
  onOpen: () => void;
}) => {
  const fileName = fileKey?.split("-")[fileKey?.split("-").length - 1];

  const handleOpen = () => {
    onOpen();
    window.open(`${process.env.REACT_APP_CLOUDFRONT_KEY}/${fileKey}`, "_blank");
  };

  return (
    <Card className="p-4 shadow-sm">
      <div className="flex justify-between">
        <div className="flex gap-4 items-center">
          <PDFIcon className="h-7" />
          <Text className="text-neutral-600 text-base leading-4 font-semibold">
            {fileName}
          </Text>
        </div>
        <div className="flex gap-2 items-center ">
          <Link onClick={handleOpen}>
            <EyeFilled className="text-base text-primary-400 cursor-pointer" />
          </Link>
          <Divider type="vertical" style={{ borderColor: "#BECBFE" }} />
          <Link onClick={handleOpen}>
            <DownloadOutlined className="text-base text-primary-400 cursor-pointer" />
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default DocumentCard;
