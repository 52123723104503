import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import { getAlert } from "../../../../redux/learner/actions";
import {
  selectAlerts,
  selectAlertsLoading,
} from "../../../../redux/learner/selector";

const mapStateToProps = (state: StateType) => ({
  alerts: selectAlerts(state),
  loading: selectAlertsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getAlert,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
