import { Button, Typography, Modal, Row, Col, Avatar, Tooltip } from "antd";
import { useEffect } from "react";
import CompanyStructureItems from "../../../../assets/images/company-structure-items/company-structure-items";
import { UserOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";

const { Text } = Typography;
const cloudFrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const UsersScreen = (props: any) => {
  const {
    users,
    actions: { getUser, deleteUser, setCurrentStep },
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
    setCurrentStep({ steps: 3, currentStep: 3 });
    getUser();
  }, []);

  const handleDeleteUser = async (id: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete this user?",
      onOk: async () => {
        await deleteUser(id);
      },
    });
  };

  return (
    <Row className="h-full  pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo
          title=""
          text="Step 3 is to define the Company’s Structure by adding User(s)."
        />
      </Col>
      <Col lg={16} className="h-full flex flex-col  max-w-[600px] gap-4">
        <div>
          <div className="flex gap-4 mb-6">
            <CompanyStructureItems.Step3 />
            <Text className="text-neutral-600 font-normal text-lg">
              User(s)
            </Text>
          </div>
          <div className="w-full mb-2">
            <Text className="text-neutral-600 font-semibold text-sm">
              User(s)
            </Text>
          </div>

          <div className="bg-white shadow-md rounded-lg p-6 flex flex-col gap-4">
            <Text className="text-neutral-600 font-normal text-sm">
              Created User(s)
            </Text>
            <div className="flex flex-col pb-4 max-h-[calc(100vh-450px)] overflow-y-auto">
              <div>
                {users &&
                  users.map((user: any) => (
                    <div
                      key={user.id}
                      className="w-full h-[50px] bg-neutral-100 rounded-lg flex items-center px-4 mb-1 justify-between"
                    >
                      <div>
                        <Avatar
                          icon={<UserOutlined />}
                          src={
                            user.profilePictureKey
                              ? `${cloudFrontKey}/${user.profilePictureKey}`
                              : ""
                          }
                        />

                        <Text className="text-neutral-600 font-semibold text-sm leading-4 ml-3">
                          {user.email}
                        </Text>
                      </div>

                      <div className="flex gap-2">
                        <Tooltip title="Edit User" placement="top">
                          <Button
                            icon={<EditOutlined />}
                            onClick={() =>
                              navigate(`/educator/user/${user.id}`)
                            }
                            className="border-none shadow-none bg-transparent"
                          />
                        </Tooltip>
                        <Tooltip title="Delete User" placement="top">
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => handleDeleteUser(user.id)}
                            className="border-none shadow-none bg-transparent"
                          />
                        </Tooltip>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full gap-4 ">
          <Button
            className="flex-1 h-[50px]"
            type="default"
            onClick={() => navigate("/educator/sign-up")}
          >
            Invite New User
          </Button>
          <Button
            type="primary"
            className="flex-1 text-sm h-[50px]"
            onClick={() => navigate("/educator/home")}
          >
            Finish
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default UsersScreen;
