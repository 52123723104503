import { Button, Form, Typography, Row, Col, Skeleton } from "antd";
import { useEffect, useState } from "react";
import Question from "../../../../shared/Question";
import Answers from "../../../../shared/Answers";
import showNotification from "../../../../../services/notificationService";
import { useSelector, useDispatch } from "react-redux";
import {
  selectQuiz,
  selectQuizLoading,
} from "../../../../../redux/learner/selector";
import { types } from "../../../../../redux/shared/actions";
import { quizTypes } from "../../../../../redux/learner/actions";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const Quiz = ({
  id,
  courseId,
  isLastLesson,
  coursePoints,
}: {
  id: string;
  courseId: string;
  isLastLesson: boolean;
  coursePoints: number;
}) => {
  const dispatch = useDispatch();
  const quiz = useSelector(selectQuiz);
  const loading = useSelector(selectQuizLoading);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
  const [userAnswers, setUserAnswers] = useState<{ [key: number]: number[] }>(
    {}
  );
  const [currentQuestion, setCurrentQuestion] = useState<any>(null);

  useEffect(() => {
    if (!quiz || quiz.id !== id)
      dispatch({ type: quizTypes.GET_QUIZ_BY_ID, payload: { id } });
  }, []);

  useEffect(() => {
    if (quiz && quiz.questions) {
      setCurrentQuestion(quiz.questions[currentIndex]);
      dispatch({
        type: types.SET_STEP,
        payload: {
          steps: quiz.questions.length,
          currentStep: currentIndex + 1,
        },
      });
    }
  }, [quiz, currentIndex]);

  useEffect(() => {
    setSelectedAnswers(userAnswers[currentIndex] || []);
  }, [currentIndex, userAnswers]);

  const handleNextQuestion = () => {
    if (selectedAnswers.length > 0) {
      setUserAnswers({
        ...userAnswers,
        [currentIndex]: selectedAnswers,
      });
      setSelectedAnswers([]);
      setCurrentIndex(currentIndex + 1);
      dispatch({ type: types.SET_NEXT_STEP });
    } else {
      showNotification("error", "Please select an answer");
    }
  };

  const handlePreviousQuestion = () => {
    setUserAnswers({
      ...userAnswers,
      [currentIndex]: selectedAnswers,
    });
    setSelectedAnswers(userAnswers[currentIndex - 1] || []);
    setCurrentIndex(currentIndex - 1);
    dispatch({ type: types.SET_PREVIOUS_STEP });
  };

  const handleSubmitQuiz = () => {
    if (selectedAnswers.length > 0 && quiz) {
      const updatedUserAnswers = {
        ...userAnswers,
        [currentIndex]: selectedAnswers,
      };

      setUserAnswers(updatedUserAnswers);

      const answers = {
        answers: Object.keys(updatedUserAnswers).map((key) => ({
          questionId: quiz.questions[parseInt(key)].id,
          answerIds: updatedUserAnswers[parseInt(key)],
        })),
      };

      const minimumCorrectQusetions =
        quiz.minimumCorrectAnswers === quiz.questions.length
          ? "all"
          : quiz.minimumCorrectAnswers;

      dispatch({
        type: quizTypes.SUBMIT_QUIZ_ANSWERS,
        payload: {
          id,
          courseId,
          answers,
          minimumCorrectQusetions,
          isLastLesson,
          coursePoints,
          navigate,
        },
      });
    } else {
      showNotification("error", "Please select an answer");
    }
  };

  return (
    <Row gutter={[40, 0]} className="pb-[60px]">
      <Col lg={12} sm={24}>
        {!loading && currentQuestion && quiz?.questions ? (
          <Question
            question={currentQuestion}
            currentQuestionIndex={currentIndex + 1}
            numberOfQuestions={quiz.questions.length}
          />
        ) : (
          <>
            <Skeleton active />
            <Skeleton.Image className="mt-4" active />
          </>
        )}
      </Col>
      <Col lg={12} sm={24}>
        {loading || !quiz ? (
          <Skeleton active />
        ) : (
          <>
            <div className="flex flex-col mb-4">
              <Text className="text-neutral-600 text-base font-semibold">
                Select correct answer/s
              </Text>
              <Text className="text-neutral-500 text-sm">
                Note: One or more answers could be correct
              </Text>
            </div>
            <Form className=" bg-white rounded-lg flex flex-col gap-2 p-6">
              <Answers
                answers={currentQuestion?.answers || []}
                selectedAnswers={selectedAnswers}
                setSelectedAnswers={setSelectedAnswers}
              />
              <div className="flex justify-between mt-3 gap-4">
                {currentIndex > 0 && (
                  <Button
                    className="flex-1  h-[50px] font-semibold text-base border-primary-200 text-primary-600"
                    onClick={handlePreviousQuestion}
                  >
                    {"<"} Back
                  </Button>
                )}

                {currentIndex + 1 !== quiz.questions.length ? (
                  <Button
                    type="primary"
                    className="flex-1 bg-primary-600 h-[50px] font-semibold text-base"
                    onClick={handleNextQuestion}
                  >
                    Continue
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    className="flex-1 bg-primary-600 h-[50px] font-semibold text-base"
                    onClick={handleSubmitQuiz}
                  >
                    Finish the Quiz
                  </Button>
                )}
              </div>
            </Form>
          </>
        )}
      </Col>
    </Row>
  );
};

export default Quiz;
