import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { setInitialSteps } from "../../../../redux/shared/actions";
import { addLesson } from "../../../../redux/educator/actions";
import { StateType } from "../../../../utils/types";

const mapStateToProps = (state: StateType) => ({});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      setInitialSteps,
      addLesson,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
