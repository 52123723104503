export const analyticsTypes = {
  GET_ANALYTICS: "GET_ANALYTICS",
  SET_ANALYTICS: "SET_ANALYTICS",
  GET_ANALYTICS_ENTITY_BY_ID: "GET_ANALYTICS_ENTITY_BY_ID",
  SET_ANALYTICS_ENTITY_BY_ID: "SET_ANALYTICS_ENTITY_BY_ID",
  GET_ANALYTICS_MODULE_BY_ID: "GET_ANALYTICS_MODULE_BY_ID",
  SET_ANALYTICS_MODULE_BY_ID: "SET_ANALYTICS_MODULE_BY_ID",
  GET_COURSE_ANALYTICS_BY_ID: "GET_COURSE_ANALYTICS_BY_ID",
  SET_COURSE_ANALYTICS_BY_ID: "SET_COURSE_ANALYTICS_BY_ID",
  GET_LESSON_ANALYTICS_BY_ID: "GET_LESSON_ANALYTICS_BY_ID",
  SET_LESSON_ANALYTICS_BY_ID: "SET_LESSON_ANALYTICS_BY_ID",
  RESET_ANALYTICS_MODULE_BY_ID: "RESET_ANALYTICS_MODULE_BY_ID",
  RESET_ALALYTICS_COURSE_BY_ID: "RESET_ALALYTICS_COURSE_BY_ID",
  RESET_ANALYTICS_LESSON_BY_ID: "RESET_ANALYTICS_LESSON_BY_ID",
  GET_BENCHMARK_ANAYLTICS: "GET_BENCHMARK_ANAYLTICS",
  SET_BENCHMARK_ANAYLTICS: "SET_BENCHMARK_ANAYLTICS",
};

export const getAnalytics = (payload: any) => ({
  type: analyticsTypes.GET_ANALYTICS,
  payload,
});

export const setAnalytics = (payload: any) => ({
  type: analyticsTypes.SET_ANALYTICS,
  payload,
});

export const getAnalyticsEntityById = (payload: any) => ({
  type: analyticsTypes.GET_ANALYTICS_ENTITY_BY_ID,
  payload,
});

export const setAnalyticsEntityById = (payload: any) => ({
  type: analyticsTypes.SET_ANALYTICS_ENTITY_BY_ID,
  payload,
});

export const getBenchmarkAnalytics = () => ({
  type: analyticsTypes.GET_BENCHMARK_ANAYLTICS,
});

export const setBenchmarkAnalytics = (payload: any) => ({
  type: analyticsTypes.SET_BENCHMARK_ANAYLTICS,
  payload,
});

export const getAnalyticsModuleById = (payload: any) => ({
  type: analyticsTypes.GET_ANALYTICS_MODULE_BY_ID,
  payload,
});

export const setAnalyticsModuleById = (payload: any) => ({
  type: analyticsTypes.SET_ANALYTICS_MODULE_BY_ID,
  payload,
});

export const getAnalyticsCourseById = (payload: any) => ({
  type: analyticsTypes.GET_COURSE_ANALYTICS_BY_ID,
  payload,
});

export const setAnalyticsCourseById = (payload: any) => ({
  type: analyticsTypes.SET_COURSE_ANALYTICS_BY_ID,
  payload,
});

export const getAnalyticsLessonById = (payload: any) => ({
  type: analyticsTypes.GET_LESSON_ANALYTICS_BY_ID,
  payload,
});

export const setAnalyticsLessonById = (payload: any) => ({
  type: analyticsTypes.SET_LESSON_ANALYTICS_BY_ID,
  payload,
});

export const resetSingleModuleAnalytics = (payload: any) => ({
  type: analyticsTypes.SET_ANALYTICS_MODULE_BY_ID,
  payload,
});

export const resetSingleCourseAnalytics = (payload: any) => ({
  type: analyticsTypes.RESET_ALALYTICS_COURSE_BY_ID,
  payload,
});

export const resetSingleLessonAnalytics = (payload: any) => ({
  type: analyticsTypes.RESET_ANALYTICS_LESSON_BY_ID,
  payload,
});
