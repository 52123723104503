import HeaderItems from "../../assets/images/header-items";

export default [
  {
    path: "/user/dashboard",
    icon: HeaderItems.Course,
    heading: "There are currently no available courses.",
    buttonText: "",
    buttonLink: "",
  },
  {
    path: "/educator/module",
    icon: HeaderItems.Module,
    heading: "There are currently no available modules.",
    buttonText: "Create New Module",
    buttonLink: "/educator/module/create",
  },
  {
    path: "/user/module",
    icon: HeaderItems.Module,
    heading: "There are currently no available modules.",
    buttonText: "",
    buttonLink: "",
  },
  {
    path: "/educator/course",
    icon: HeaderItems.Course,
    heading: "There are currently no available courses.",
    buttonText: "Create New Course",
    buttonLink: "/educator/course/create",
  },
  {
    path: "/user/course",
    icon: HeaderItems.Course,
    heading: "There are currently no available courses.",
    buttonText: "",
    buttonLink: "",
  },
  {
    path: "/educator/lesson",
    icon: HeaderItems.Lesson,
    heading: "There are currently no available lessons.",
    buttonText: "Create New Lesson",
    buttonLink: "/educator/lesson/create",
  },
  {
    path: "/educator/alert",
    icon: HeaderItems.Alert,
    heading: "There are currently no Alerts.",
    buttonText: "Create New Alert",
    buttonLink: "/educator/alert/create",
  },
  {
    path: "/user/alert",
    icon: HeaderItems.Alert,
    heading: "There are currently no Alerts.",
    buttonText: "",
    buttonLink: "",
  },
  {
    path: "/educator/quiz",
    icon: HeaderItems.Quiz,
    heading: "There are currently no Quiz.",
    buttonText: "Create New Quiz",
    buttonLink: "/educator/quiz/create",
  },
  {
    path: "/educator/quizzie",
    icon: HeaderItems.Quizzie,
    heading: "There are currently no Quizzie.",
    buttonText: "Create New Quizzie",
    buttonLink: "/educator/quizzie/create",
  },
  {
    path: "/user/quizzie",
    icon: HeaderItems.Quizzie,
    heading: "There are currently no Quizzie available",
  },
];
