import { Button, Space, Typography } from "antd";
import { AppleOutlined, GoogleOutlined } from "@ant-design/icons";
import logoImage from "../../../assets/images/company-logo.webp";

const { Title, Paragraph } = Typography;

const AppDownload = () => {
  return (
    <div className="p-12 text-center">
      <img src={logoImage} alt="auth-image" className="w-60 m-auto" />
      <Title level={2}>Get Our App</Title>
      <Paragraph>Download our app to enjoy the full experience.</Paragraph>
      <div className="flex flex-col gap-3">
        <Button
          type="primary"
          icon={<AppleOutlined />}
          href="https://apps.apple.com/ba/app/cyberedukacija/id6667091356"
          target="_blank"
          rel="noopener noreferrer"
          className="ant-btn-lg"
        >
          Download on the App Store
        </Button>
        <Button
          type="primary"
          icon={<GoogleOutlined />}
          href="https://play.google.com/store/apps/details?id=com.tfl.CyberEdukacija"
          target="_blank"
          rel="noopener noreferrer"
          className="ant-btn-lg"
        >
          Get it on Google Play
        </Button>
      </div>
    </div>
  );
};

export default AppDownload;
