import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface ChartProps {
  chartData: any;
  xAxisDataKey: string;
  type: "activity" | "performance";
}

const Chart: React.FC<ChartProps> = ({ chartData, xAxisDataKey, type }) => {
  const lineColors =
    type === "activity"
      ? { inactive: "#FB7185", active: "#5063EE" }
      : { inactive: "#59ABB2", active: "#5063EE" };

  const lineLabels =
    type === "activity"
      ? { inactive: "inactive", active: "active" }
      : { inactive: "incomplete", active: "completed" };

  return (
    <ResponsiveContainer
      minWidth={300}
      minHeight={320}
      style={{
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0px 2px 6px 0px #1018280F",
        padding: "20px 20px 20px 0px",
      }}
    >
      <LineChart data={chartData}>
        <CartesianGrid vertical={false} strokeDasharray="3" />
        <XAxis
          tick={{ fill: "#9CA3AF", fontSize: 12 }}
          tickMargin={16}
          axisLine={false}
          tickLine={false}
          dataKey={xAxisDataKey}
        />
        <YAxis
          tick={{ fill: "#9CA3AF", fontSize: 12 }}
          tickMargin={16}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip />
        <Legend
          iconSize={14}
          align="right"
          iconType="circle"
          verticalAlign="top"
          margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
        />
        <Line
          type="monotone"
          dataKey={lineLabels.inactive}
          stroke={lineColors.inactive}
          strokeWidth={3}
          dot={false}
        />
        <Line
          type="monotone"
          dataKey={lineLabels.active}
          stroke={lineColors.active}
          strokeWidth={3}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
