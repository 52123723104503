import { useState, useEffect } from "react";
import { Col, Row, Form } from "antd";

import QuizForm from "../../Quiz/Create/Form/QuizForm";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import { useNavigate } from "react-router-dom";
import QuizzieStructure from "./Structure/QuizzieStructure";
import showNotification from "../../../../services/notificationService";

const CreateQuizzie = (props: any) => {
  const {
    loadingSubmission,
    actions: { setInitialSteps, setNextStep, setPreviousStep, addQuizzie },
  } = props;

  const [quizStep, setQuizStep] = useState(1);
  const [formValues, setFormValues] = useState({
    departmentIds: [],
    positionIds: [],
    userIds: [],
  });
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    setInitialSteps(2);
  }, []);

  const onFinishQuiz = () => {
    if (
      !formValues.departmentIds?.length &&
      !formValues.positionIds?.length &&
      !formValues.userIds?.length
    ) {
      showNotification(
        "error",
        "Please select at least one department, position or user."
      );
      return;
    }
    addQuizzie({ quizzie: formValues, navigate });
  };

  return (
    <>
      <Form
        form={form}
        name="quizForm"
        autoComplete="off"
        size="small"
        className="h-full"
        initialValues={{
          questions: [
            {
              question: "",
              answers: [
                {
                  answer: "",
                  isCorrect: false,
                },
              ],
            },
          ],
        }}
        onFinish={onFinishQuiz}
        onValuesChange={() => setFormValues(form.getFieldsValue())}
      >
        {quizStep === 1 ? (
          <Row>
            <Col lg={8}>
              <CreateEntityInfo
                title="Test your students"
                text="Create a Quizzie by adding the questions and related answers. Once you finalize all questions proceed to Finish Quizzie."
              ></CreateEntityInfo>
            </Col>
            <Col lg={16}>
              <QuizForm
                form={form}
                setNextStep={setNextStep}
                setQuizStep={setQuizStep}
                isEdit={false}
              ></QuizForm>
            </Col>
          </Row>
        ) : (
          <QuizzieStructure
            form={form}
            formValues={formValues}
            setFormValues={setFormValues}
            setQuizStep={setQuizStep}
            setPreviousStep={setPreviousStep}
            loading={loadingSubmission}
          ></QuizzieStructure>
        )}
      </Form>
    </>
  );
};

export default CreateQuizzie;
