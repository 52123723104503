import {
  SetLoginPayload,
  SetRegisterPayload,
  SetResetPasswordPayload,
  SetSendResetPasswordPayload,
} from "../../utils";
import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

const loginUrl = apiRoutes.login;
const registerUrl = apiRoutes.register;
const resetPasswordUrl = apiRoutes.resetPassword;
const sendResetPasswordUrl = apiRoutes.sendResetPassword;

export const loginService = async (loginData: SetLoginPayload) =>
  handleApiCall(() => ApiService.post(`${loginUrl}`, loginData));

export const registerService = async (registerData: SetRegisterPayload) =>
  handleApiCall(() => ApiService.post(`${registerUrl}`, registerData));

export const resetPasswordService = async (
  resetPasswordData: SetResetPasswordPayload
) =>
  handleApiCall(() =>
    ApiService.post(`${resetPasswordUrl}`, resetPasswordData)
  );

export const sendResetPasswordService = async (
  sendResetPasswordData: SetSendResetPasswordPayload
) =>
  handleApiCall(() =>
    ApiService.post(`${sendResetPasswordUrl}`, sendResetPasswordData)
  );

export const uploadInviteUsersService = async (inviteUsersData: FormData) =>
  handleApiCall(() =>
    ApiService.post(`${apiRoutes.uploadInviteUsers}`, inviteUsersData)
  );
