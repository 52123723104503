import { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import type { MenuProps } from "antd";
import { Layout, Menu, Avatar, Tooltip } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import MenuItems from "../../../assets/images/menu-items";
import { selectUser } from "../../../redux/auth/selector";
import { userTypes } from "../../../redux/learner/actions";
import { getLoggedUser, types } from "../../../redux/auth/actions";
import "../../../assets/styles/sidebar.scss";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: () => void
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
  } as MenuItem;
}

const pathToKeyMap: Record<string, string> = {
  "/user/home": "userHome",
  "/user/dashboard": "userDashboard",
  "/user/module": "userModules",
  "/user/course": "userCourses",
  "/user/alert": "userAlerts",
  "/user/quizzie": "userQuizzie",
};

const UserSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [siderWidth, setSiderWidth] = useState(360);

  const baseItems = useMemo(
    () => [
      getItem("Home", "userHome", <MenuItems.Home />, undefined, () =>
        navigate("/user/home")
      ),
      getItem(
        "Dashboard",
        "userDashboard",
        <MenuItems.Dashboard />,
        undefined,
        () => navigate("/user/dashboard")
      ),
      getItem("Modules", "userModules", <MenuItems.Module />, undefined, () =>
        navigate("/user/module")
      ),
      getItem("Courses", "userCourses", <MenuItems.Course />, undefined, () =>
        navigate("/user/course")
      ),
      getItem("Alerts", "userAlerts", <MenuItems.Alert />, undefined, () =>
        navigate("/user/alert")
      ),
      getItem("Quizzie", "userQuizzie", <MenuItems.Quizzie />, undefined, () =>
        navigate("/user/quizzie")
      ),
      getItem(
        "Logout",
        "logout",
        <LogoutOutlined style={{ fontSize: 18 }} />,
        undefined,
        () => dispatch({ type: userTypes.LOGOUT, payload: { navigate } })
      ),
    ],
    [dispatch, navigate]
  );

  const [items, setItems] = useState(baseItems);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 1024) {
        setSiderWidth(200);
        return;
      }
      if (screenWidth < 1200) {
        setSiderWidth(250);
        return;
      }
      if (screenWidth < 1400) {
        setSiderWidth(260);
        return;
      }
      if (screenWidth < 1500) {
        setSiderWidth(280);
        return;
      }
      if (screenWidth < 1600) {
        setSiderWidth(320);
        return;
      }
      if (screenWidth < 1800) {
        setSiderWidth(360);
        return;
      }
    };

    window.addEventListener("resize", handleResize);

    // Set the initial width
    handleResize();

    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (
      user &&
      user.role.length > 1 &&
      !items.find((item) => item?.key === "switch")
    ) {
      const newItem = getItem(
        "Switch to Educator",
        "switch",
        <UserSwitchOutlined style={{ fontSize: 18 }} />,
        undefined,
        () => dispatch({ type: types.SWITCH_ROLE, payload: { navigate } })
      );
      const newItems = [...items];
      newItems.splice(newItems.length - 1, 0, newItem);
      setItems(newItems);
    }
  }, [user, navigate, dispatch, items]);

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
      width={siderWidth}
    >
      <div className="flex flex-col gap-7">
        <div className="h-20 bg-primary-850">
          <div className="px-5 py-6 flex justify-items-center items-center h-full gap-3">
            <Avatar
              size={48}
              icon={<UserOutlined />}
              src={"../company-logo.webp"}
            />
            <div className="text-white">CyberEdukacija</div>
          </div>
        </div>

        <div className="px-5 flex justify-items-center items-center h-full  gap-3">
          <Tooltip title="Profile settings">
            <Avatar
              size={48}
              src={
                user?.profilePictureKey &&
                process.env.REACT_APP_CLOUDFRONT_KEY +
                  "/" +
                  user?.profilePictureKey
              }
              icon={<UserOutlined />}
              className="cursor-pointer"
              onClick={() => navigate("/settings/" + user?.id)}
            />
          </Tooltip>
          <div>
            <div>{user?.name ?? user?.email}</div>
            <div className="text-primary-600">User</div>
          </div>
        </div>
        <Menu
          theme="light"
          mode="inline"
          items={items}
          selectedKeys={[pathToKeyMap[location.pathname]]}
        />
      </div>
    </Sider>
  );
};

export default UserSidebar;
