import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Input, Skeleton } from "antd";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import ProgressInfoBox from "../components/ProgressInfoBox";
import FilterButtons from "../../../shared/FilterButtons";
import Chart from "../components/Chart";
import TimelineSelection from "../components/TimelineSelection";
import MenuItems from "../../../../assets/images/menu-items";
import ListHeader from "../components/ListHeader";
import CardProgress from "../components/CardProgress";
import PerformanceListItem from "../components/PerformanceUserListItem";

const EducatorDashboardModulesScreen = (props: any) => {
  const {
    modules,
    singleModule,
    benchmarkLoaded,
    benchmark,
    actions: {
      getAnalytics,
      getAnalyticsModuleById,
      getBenchmarkAnalytics,
      resetSingleModuleAnalytics,
    },
  } = props;

  const navigate = useNavigate();

  const [filter, setFilter] = useState("ALL");
  const [timeLineValue, setTimeLineValue] = useState();
  const [singleModuleActive, setSingleModuleActive] = useState(false);
  const [modulesChartData, setModulesChartData] = useState([]);
  const [statistics, setStatistics] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredModules, setFilteredModules] = useState(modules?.data);
  const [filteredSingleModuleData, setFilteredSingleModuleData] = useState(
    singleModule?.data
  );
  const [singleModuleTitle, setSingleModuleTitle] = useState("");

  const filterProps = [
    { key: "All", value: "ALL" },
    { key: "Finished", value: "FINISHED" },
    { key: "In Progress", value: "INPROGRESS" },
  ];

  const timeLineOptions = [
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
    { value: "year", label: "Year" },
  ];

  useEffect(() => {
    document.body.classList.add("educator-dashboard-layout");

    return () => {
      document.body.classList.remove("educator-dashboard-layout");
    };
  }, []);

  useEffect(() => {
    getAnalytics({
      type: "module",
    });
  }, []);

  useEffect(() => {
    if (modules.loaded) {
      const completedModules =
        modules?.data?.filter((module: any) => module.progress === "100")
          ?.length || 0;
      const completedModulesPercentage = Math.round(
        (completedModules / modules?.data?.length) * 100
      );
      const inProgressModules =
        modules?.data?.filter((module: any) => module.progress !== "100")
          ?.length || 0;
      const inProgressModulesPercentage = Math.round(
        (inProgressModules / modules?.data?.length) * 100
      );
      setStatistics({
        completedModules,
        completedModulesPercentage,
        inProgressModules,
        inProgressModulesPercentage,
      });
    }
  }, [modules]);

  useEffect(() => {
    if (singleModuleActive) {
      let filtered = singleModule?.data;

      if (filter === "FINISHED") {
        filtered = filtered.filter((user: any) => user.progress === "100");
      } else if (filter === "INPROGRESS") {
        filtered = filtered.filter((user: any) => user.progress !== "100");
      }

      if (searchTerm) {
        filtered = filtered.filter((user: any) =>
          user.userName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      setFilteredSingleModuleData(filtered);
    } else {
      let filtered = modules?.data;

      if (filter === "FINISHED") {
        filtered = filtered.filter((module: any) => module.progress === "100");
      } else if (filter === "INPROGRESS") {
        filtered = filtered.filter((module: any) => module.progress !== "100");
      }

      if (searchTerm) {
        filtered = filtered.filter((module: any) =>
          module.moduleTitle.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      setFilteredModules(filtered);
    }
  }, [searchTerm, filter, modules, singleModule]);

  useEffect(() => {
    if (!benchmarkLoaded) {
      getBenchmarkAnalytics();
    }
  }, []);

  useEffect(() => {
    if (benchmarkLoaded) {
      modulesWeekData();
    }
  }, [benchmarkLoaded]);

  const handleChangeTimeline = (value: string) => {
    if (value === "week") {
      modulesWeekData();
      return;
    }
    if (value === "month") {
      modulesMonthData();
      return;
    }
    if (value === "year") {
      modulesYearData();
      return;
    }
  };

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };

  const modulesWeekData = () => {
    const weekModulesData = benchmark?.modules?.slice(0, 7);
    const chartData = getChartDataForWeek(weekModulesData);
    setModulesChartData(chartData);
  };

  const modulesMonthData = () => {
    const monthModulesData = benchmark?.modules?.slice(0, 30);
    const chartData = getChartDataForMonth(monthModulesData);
    setModulesChartData(chartData);
  };

  const modulesYearData = () => {
    const yearModulesData = benchmark?.modules?.slice(0, 365);
    const chartData = getChartDataForYear(yearModulesData);
    setModulesChartData(chartData);
  };

  const getChartDataForWeek = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).toLocaleDateString("en-US", {
        weekday: "long",
      }),
    }));
  };

  const getChartDataForMonth = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).getDate(),
    }));
  };

  const getChartDataForYear = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).toLocaleDateString("en-US", {
        month: "long",
      }),
    }));
  };

  const handleModuleSelection = (module: any) => {
    getAnalyticsModuleById({ id: module.moduleId });
    setSingleModuleActive(true);
    setSingleModuleTitle(module?.moduleTitle);
  };

  const ModulesList = () => {
    if (!modules || modules?.loading) {
      return <Skeleton active />;
    }

    if (modules?.loaded && filteredModules?.length === 0) {
      return (
        <div className="flex flex-col gap-8 items-center">
          <div className="p-4">
            <MenuItems.Module className="h-20 w-20 fill-primary-600" />
          </div>
          <div className="text-neutral-600 text-xl fill-primary-600 font-bold text-center w-3/4">
            There are currently no available modules.
          </div>

          <Button
            onClick={() => navigate("/educator/module/create")}
            className="w-full bg-primary-600 h-10 text-base font-semibold text-white"
          >
            Create New Module
          </Button>
        </div>
      );
    }

    if (modules?.loaded) {
      return filteredModules?.map((module: any, index: number) => (
        <CardProgress
          key={index}
          classes=""
          title={module.moduleTitle}
          progress={module.progress}
          isFinished={module?.progress === "100"}
          footerButton={
            <Button
              className="bg-primary-600 text-white border-none text-sm leading-4 font-semibold"
              onClick={() => handleModuleSelection(module)}
            >
              View Module
            </Button>
          }
          size="small"
        ></CardProgress>
      ));
    }
  };

  const SingleModuleList = () => {
    if (!singleModule || singleModule?.loading) {
      return <Skeleton active />;
    }

    if (singleModule?.loaded && filteredSingleModuleData?.length === 0) {
      return (
        <div className="flex flex-col gap-8 items-center">
          <div className="p-4">
            <MenuItems.Module className="h-20 w-20 fill-primary-600" />
          </div>
          <div className="text-neutral-600 text-xl fill-primary-600 font-bold text-center w-3/4">
            There are currently no available users.
          </div>
        </div>
      );
    }

    if (singleModule?.loaded && filteredSingleModuleData?.length > 1) {
      return filteredSingleModuleData?.map((user: any) => (
        <PerformanceListItem
          key={user.userId}
          user={user}
          completedEntities={user.completedCourses}
          totalEntities={user.totalCourses}
        ></PerformanceListItem>
      ));
    }
  };

  const backToAllModules = () => {
    setSingleModuleActive(false);
    setSingleModuleTitle("");
    resetSingleModuleAnalytics();
  };

  return (
    <Row>
      <Col lg={12} className="pr-[80px]">
        <div>
          <div className="text-primary-600 text-xl font-semibold mb-2">
            Performance
          </div>
          <div className="text-neutral-500 text-sm">
            Monitor the performance of your Modules and filter the data by time
            frame (week, month, year).
          </div>

          <div className="mt-5 flex justify-between gap-4">
            <div className="flex gap-4">
              <TimelineSelection
                selectedOption={timeLineValue}
                onSelectionChange={handleChangeTimeline}
                options={timeLineOptions}
              ></TimelineSelection>
            </div>
            <Button
              color="primary"
              type="link"
              onClick={() => navigate("/educator/benchmark")}
            >
              All Graphs
            </Button>
          </div>
          <div className="mt-3">
            <Chart
              chartData={modulesChartData}
              xAxisDataKey="dateDay"
              type="performance"
            ></Chart>
          </div>
          <div className="py-7">
            <Row className="h-full" gutter={24}>
              <Col lg={12}>
                <ProgressInfoBox
                  percentage={statistics?.completedModulesPercentage}
                  heading="Completed Modules"
                  totalNumber={statistics?.completedModules}
                  type="performance"
                ></ProgressInfoBox>
              </Col>
              <Col lg={12}>
                <ProgressInfoBox
                  percentage={statistics?.inProgressModulesPercentage}
                  heading="In progress"
                  totalNumber={statistics?.inProgressModules}
                  type="performance"
                ></ProgressInfoBox>
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      <Col lg={12} className="pl-[80px]">
        {singleModuleActive && (
          <div
            onClick={() => backToAllModules()}
            className="full-overview-selector text-primary-500 font-medium cursor-pointer"
          >
            &lt; Back to full overview
          </div>
        )}
        <div className="bg-primary-100 w-full">
          <div className="bg-white p-5 w-full analytics-selector flex flex-col gap-4 rounded-lg">
            <div>
              <ListHeader
                title={singleModuleActive ? singleModuleTitle : "Modules"}
                Icon={MenuItems.Module}
              />
            </div>
            <div className="analyitcs-searchbox">
              <Input
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search"
                prefix={<SearchIcon className="" />}
                size="small"
              />
            </div>
          </div>
          <div className="flex justify-center mb-4">
            <FilterButtons
              selectedFilter={filter}
              handleFilterChange={handleFilterChange}
              filterOptions={filterProps}
            ></FilterButtons>
          </div>
          <div className="p-4 bg-white rounded-lg">
            <div className="flex flex-col gap-3 max-h-[calc(100vh-450px)] overflow-y-auto">
              <>
                {singleModuleActive ? (
                  <SingleModuleList></SingleModuleList>
                ) : (
                  <ModulesList></ModulesList>
                )}
              </>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default EducatorDashboardModulesScreen;
