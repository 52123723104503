import {
  Input,
  Button,
  DatePicker,
  Select,
  Avatar,
  Upload,
  Row,
  Col,
  Form,
} from "antd";
import { UserOutlined, CameraOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

const { Option } = Select;
const cloudFrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const EditUserScreen = (props: any) => {
  const {
    user,
    departments,
    positions,
    actions: { getDepartment, getPosition, getUserById, editUser },
  } = props;
  const [form] = Form.useForm();
  const [isChanged, setIsChanged] = useState(false);
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const { id } = useParams();

  useEffect(() => {
    getDepartment();
    getPosition();
    getUserById({ id });
  }, []);

  useEffect(() => {
    if (!user) return;
    setAvatarUrl(
      user.profilePictureKey ? `${cloudFrontKey}/${user.profilePictureKey}` : ""
    );
    form.setFieldsValue({
      name: user.name,
      email: user.email,
      dateOfBirth: dayjs(user.dateOfBirth),
      language: user.language,
      department: user.departmentId,
      position: user.positionId,
    });
  }, [user, form]);

  const handleFormChange = () => {
    const fieldsChanged = form.isFieldsTouched();
    setIsChanged(fieldsChanged);
  };

  const handleSaveChanges = () => {
    form.validateFields().then((values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("dateOfBirth", values.dateOfBirth.format("YYYY-MM-DD"));
      formData.append("language", values.language);
      if (values.department) formData.append("departmentId", values.department);
      if (values.position) formData.append("positionId", values.position);

      if (profilePicture) {
        formData.append("profilePicture", profilePicture);
      }
      editUser({ id, user: formData });
    });
  };

  const handleUploadChange = (info: any) => {
    const file = info.file.originFileObj || info.file;

    if (file) {
      setProfilePicture(file);
      try {
        const imageUrl = URL.createObjectURL(file);
        setAvatarUrl(imageUrl);
      } catch (error) {
        console.error("Error creating object URL: ", error);
      }

      setIsChanged(true);
    } else {
      console.error("No valid file provided for upload.");
    }
  };

  const beforeUpload = (file: File) => {
    return false;
  };

  return (
    <Row className="h-full pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo title="" text="Edit User" />
      </Col>
      <Col lg={16} className="h-full flex flex-col max-w-[600px]">
        {user && (
          <>
            <div className="flex justify-center mb-4">
              <div className="relative">
                <Avatar size={150} icon={<UserOutlined />} src={avatarUrl} />
                <Upload
                  className="absolute bottom-0 right-0"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleUploadChange}
                >
                  <Button
                    shape="circle"
                    icon={<CameraOutlined />}
                    className="bg-blue-500 text-white"
                  />
                </Upload>
              </div>
            </div>

            <Form
              form={form}
              layout="vertical"
              onValuesChange={handleFormChange}
              initialValues={{
                name: user.name,
                email: user.email,
                dateOfBirth: dayjs(user.dateOfBirth),
                language: user.language,
                department: user.departmentId,
                position: user.positionId,
              }}
            >
              <Form.Item name="name" label="Name">
                <Input placeholder="Name" size="large" />
              </Form.Item>

              <Form.Item name="email" label="Email">
                <Input placeholder="Email" size="large" />
              </Form.Item>

              <Form.Item name="dateOfBirth" label="Date of Birth">
                <DatePicker
                  placeholder="Date of Birth"
                  size="large"
                  className="w-full h-[50px]"
                />
              </Form.Item>

              <Form.Item name="language" label="Language">
                <Select size="large" className="w-full min-h-[50px]">
                  <Option value="English">English</Option>
                  <Option value="Bosnian">Bosnian</Option>
                </Select>
              </Form.Item>

              <Form.Item name="department" label="Department">
                <Select size="large" className="w-full min-h-[50px]">
                  {departments.map((dept: any) => (
                    <Option key={dept.id} value={dept.id}>
                      {dept.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="position" label="Position">
                <Select size="large" className="w-full min-h-[50px]">
                  {positions.map((pos: any) => (
                    <Option key={pos.id} value={pos.id}>
                      {pos.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  block
                  size="large"
                  className="bg-primary-600 h-[50px]"
                  onClick={handleSaveChanges}
                  disabled={!isChanged}
                >
                  Save Changes
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Col>
    </Row>
  );
};

export default EditUserScreen;
