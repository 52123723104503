import { call, all, put, takeLatest } from "redux-saga/effects";

import { lessonTypes as types } from "../actions";
import { AxiosResponse } from "axios";
import { markLessonAsCompletedService } from "../../../services/learner/lesson";
import { EntityIdPayload } from "../../../utils";

export default function* learnerLessonSagas() {
  yield all([takeLatest(types.MARK_AS_COMPLETE, handleMarkAsComplete)]);
}

function* handleMarkAsComplete(action: any) {
  const response: AxiosResponse = yield call(
    markLessonAsCompletedService,
    action.payload.id
  );
  if (response.status === 200 && action.payload.isLastLesson) {
    action.payload.navigate(`/success/user/course`, {
      state: { points: action.payload.points },
    });
  }
}
