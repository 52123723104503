import { useRef, useState } from "react";
import {
  PlayCircleFilled,
  PauseCircleFilled,
  StepBackwardOutlined,
  SoundFilled,
  MutedFilled,
} from "@ant-design/icons";

const VideoPlayer = ({
  videoKey,
  onEnd,
}: {
  videoKey: string;
  onEnd: () => void;
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentProgress =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(currentProgress);
      setCurrentTime(videoRef.current.currentTime);
      setDuration(videoRef.current.duration);
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    setProgress(100);
    onEnd();
  };

  const formatTime = (time: number) => {
    if (!time) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const rewind = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = Math.max(
        videoRef.current.currentTime - 10,
        0
      );
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="relative w-full mx-auto text-center">
      <video
        ref={videoRef}
        src={`${process.env.REACT_APP_CLOUDFRONT_KEY}/${videoKey}`}
        onTimeUpdate={handleTimeUpdate}
        onEnded={handleVideoEnd}
        controls={false}
        className="w-full rounded-lg"
      />

      <div className="absolute bottom-0 left-0 w-full px-4 py-2 bg-black bg-opacity-50 text-white flex flex-col items-center space-y-2 rounded-lg">
        <div className="flex items-center justify-between w-full text-2xl">
          <button onClick={rewind}>
            <StepBackwardOutlined />
          </button>
          <button onClick={togglePlayPause}>
            {isPlaying ? <PauseCircleFilled /> : <PlayCircleFilled />}
          </button>
          <button onClick={toggleMute}>
            {isMuted ? <MutedFilled /> : <SoundFilled />}
          </button>
        </div>
        <div className="w-full h-2 bg-gray-300 rounded-full overflow-hidden mt-2">
          <div
            className="h-full bg-primary-500"
            style={{ width: `${progress}%` }}
          />
        </div>
        <div className="flex justify-between w-full text-xs text-gray-300 mt-2">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
