import { produce } from "immer";

import { SetQuizByIdPayload, SetQuizzesPayload } from "../../../utils";
import { quizTypes as types } from "../actions";
import { EducatorQuizStateType } from "../../../utils/types";

const defaultState: EducatorQuizStateType = {
  quizzes: [],
  quiz: null,
  loaded: false,
  loading: false,
  loadingSubmission: false,
};

const handleSetQuizzes = (
  state: EducatorQuizStateType,
  payload: SetQuizzesPayload
) => {
  state.quizzes = payload.quizzes;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetQuiz = (
  state: EducatorQuizStateType,
  payload: SetQuizByIdPayload
) => {
  state.quiz = payload.quiz;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSubmissionComplete = (state: EducatorQuizStateType) => {
  state.loadingSubmission = false;
  return state;
};

export default produce(
  (state: EducatorQuizStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_QUIZ:
        return handleSetQuizzes(state, action.payload);
      case types.SET_QUIZ_BY_ID:
        return handleSetQuiz(state, action.payload);
      case types.QUIZ_SUBMISSION_COMPLETE:
        return handleSubmissionComplete(state);
      case types.ADD_QUIZ:
        return {
          ...state,
          loadingSubmission: true,
        };
      case types.GET_QUIZ:
        return {
          ...state,
          loading: true,
        };
      case types.GET_QUIZ_BY_ID:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);
