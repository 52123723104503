import { Row, Col, Typography, Image, Skeleton } from "antd";
import VideoPlayer from "./VideoPlayer";
import DocumentCard from "../../../../shared/DocumentCard";
import ActionButtons from "./ActionButtons";
import { ReactComponent as PlayIcon } from "../../../../../assets/images/play-media.svg";

const { Text } = Typography;

const Lesson = ({
  lesson,
  handleVideoEnd,
  handleDocumentOpen,
  handleQuizToggle,
  handleNextLesson,
  videoWatched,
  documentOpened,
  isLastLesson,
}: {
  lesson: any;
  videoWatched: boolean;
  documentOpened: boolean;
  isLastLesson: boolean;
  handleVideoEnd: () => void;
  handleDocumentOpen: () => void;
  handleQuizToggle: () => void;
  handleNextLesson: () => void;
}) => {
  if (!lesson) {
    return <Skeleton active />;
  }

  return (
    <Row className="pb-[60px]">
      <Col lg={18} sm={24} className="lg:pr-4">
        <div className="p-6 shadow-md rounded-lg bg-white">
          <div className="flex gap-3 mb-6">
            <PlayIcon />
            <Text className="text-neutral-600 font-semibold text-base">
              Lesson {String(lesson.orderIndex).padStart(2, "0")}
            </Text>
            <Text className="text-neutral-600 text-base">{lesson.title}</Text>
          </div>
          {lesson.videoKey ? (
            <VideoPlayer videoKey={lesson.videoKey} onEnd={handleVideoEnd} />
          ) : (
            <Image
              src={`${process.env.REACT_APP_CLOUDFRONT_KEY}/${lesson.coverImageKey}`}
              alt="lesson cover"
              className="w-full h-96 object-cover rounded-lg"
            />
          )}
        </div>
        {lesson.description && (
          <div className="mt-5">
            <Text className="text-primary-600 font-semibold text-base">
              Description
            </Text>
            <div className=" overflow-y-auto p-4 bg-white rounded-lg mt-2 shadow-md">
              <Text className="text-neutral-600 text-base">
                {lesson.description}
              </Text>
            </div>
          </div>
        )}
      </Col>
      <Col lg={6} sm={24} className="lg:pl-4 flex flex-col justify-between">
        <div className="flex flex-col gap-4 max-h-[400px]">
          {lesson.fileKey && (
            <>
              <Text className="text-primary-600 font-semibold text-base">
                Additional documents
              </Text>
              <DocumentCard
                fileKey={lesson.fileKey}
                onOpen={handleDocumentOpen}
              />
            </>
          )}
        </div>

        <ActionButtons
          onQuizClick={handleQuizToggle}
          onNextLessonClick={handleNextLesson}
          showQuizButton={!!lesson.quiz}
          isQuizDisabled={
            (!videoWatched && !!lesson.videoKey) ||
            (!documentOpened && !!lesson.fileKey)
          }
          isNextLessonDisabled={
            (lesson.videoKey && !videoWatched) ||
            (lesson.fileKey && !documentOpened) ||
            (lesson.quiz && lesson.quiz.isMandatory)
          }
          isLastLesson={isLastLesson}
        />
      </Col>
    </Row>
  );
};

export default Lesson;
