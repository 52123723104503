import { useEffect, useState } from "react";
import { Col, Row, Form, Skeleton } from "antd";

import { useNavigate, useParams } from "react-router-dom";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import QuizForm from "../Create/Form/QuizForm";
import QuizOptions from "../Create/Options/QuizOptions";

const QuizDetails = (props: any) => {
  const {
    actions: {
      setInitialSteps,
      setNextStep,
      setPreviousStep,
      getQuizById,
      editQuiz,
    },
    quiz,
    loading,
  } = props;

  const [formValues, setFormValues] = useState({});
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [quizStep, setQuizStep] = useState(1);
  const [deletedImages, setDeletedImages] = useState([]);
  const [existingImages, setExistingImages] = useState([]);

  useEffect(() => {
    setInitialSteps(2);
  }, []);

  useEffect(() => {
    getQuizById({ id });
  }, []);

  useEffect(() => {
    if (quiz) {
      form.setFieldsValue(quiz);
      setFormValues(quiz);

      const images = quiz.questions.reduce(
        (arr: any[], question: any, index: number) => {
          if (question.imageKey) {
            arr.push({
              questionIndex: index,
              imageKey: question.imageKey,
            });
          }
          return arr;
        },
        []
      );
      setExistingImages(images);
    }
  }, [quiz]);

  const onFinishEditQuiz = () => {
    editQuiz({ quiz: formValues, id, navigate, deletedImages, existingImages });
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {quiz && (
        <Form
          form={form}
          name="quizForm"
          autoComplete="off"
          size="small"
          className="h-full"
          initialValues={quiz}
          onValuesChange={() => setFormValues(form.getFieldsValue())}
          onFinish={onFinishEditQuiz}
        >
          {quizStep === 1 ? (
            <Row>
              <Col lg={8}>
                <CreateEntityInfo
                  title="Test your students"
                  text="Create a Quiz by adding the questions and related answers. Once you finalize all questions proceed to Finish Quiz."
                ></CreateEntityInfo>
              </Col>
              <Col lg={16}>
                <QuizForm
                  form={form}
                  setNextStep={setNextStep}
                  setQuizStep={setQuizStep}
                  isEdit={true}
                  quiz={quiz}
                  setDeletedImages={setDeletedImages}
                ></QuizForm>
              </Col>
            </Row>
          ) : (
            <QuizOptions
              form={form}
              formValues={formValues}
              setFormValues={setFormValues}
              setQuizStep={setQuizStep}
              setPreviousStep={setPreviousStep}
              isEdit={true}
            ></QuizOptions>
          )}
        </Form>
      )}
    </>
  );
};

export default QuizDetails;
