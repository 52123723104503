import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { StateType } from "../../../../utils";
import {
  getCourseById,
  markAsComplete as markLessonAsCompleted,
} from "../../../../redux/learner/actions";
import {
  setCurrentStep,
  setHeaderTitles,
} from "../../../../redux/shared/actions";
import { selectCourse } from "../../../../redux/learner/selector";
const mapStateToProps = (state: StateType) => ({
  course: selectCourse(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getCourseById,
      markLessonAsCompleted,
      setCurrentStep,
      setHeaderTitles,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
