/* eslint-disable require-yield */
import { call, all, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { types, loginSuccess, setLoggedUser } from "../actions";
import {
  LoginActionType,
  RegisterActionType,
  ResetPasswordActionType,
  SendResetPasswordActionType,
  ChooseRoleActionType,
} from "../../../utils";
import {
  loginService,
  registerService,
  resetPasswordService,
  sendResetPasswordService,
  uploadInviteUsersService,
} from "../../../services/auth";
import { getLoggedUserService } from "../../../services/shared";
import StorageService from "../../../services/storageService";
import { userRole, educatorRole } from "../../../utils/constants/userRoles";
import routes from "../../../utils/constants/routes";
import showNotification from "../../../services/notificationService";

export default function* authSagas() {
  yield all([
    takeLatest(types.LOGIN_ACTION, handleLogin),
    takeLatest(types.REGISTER_ACTION, handleRegister),
    takeLatest(types.RESET_PASSWORD_ACTION, handleResetPassword),
    takeLatest(types.SEND_RESET_PASSWORD_EMAIL_ACTION, handleSendResetPassword),
    takeLatest(types.CHOOSE_ROLE_ACTION, handleChooseRole),
    takeLatest(types.SWITCH_ROLE, handleSwitchRole),
    takeLatest(types.GET_LOGGED_USER, handleGetLoggedUser),
    takeLatest(types.UPLOAD_INVITE_USERS, handleUploadInviteUsers),
  ]);
}

function* handleLogin(action: LoginActionType) {
  const { data }: AxiosResponse = yield call(loginService, action.payload);
  if (!data?.user) {
    return;
  }

  const user = data.user;

  if (user.role.length === 1) {
    const accessToken = data.accessToken;
    const refreshToken = data.refreshToken;
    StorageService.setStringItem("accessToken", accessToken);
    StorageService.setStringItem("refreshToken", refreshToken);
    yield put(loginSuccess({ token: accessToken, user }));

    switch (user.role[0]) {
      case userRole:
        action.payload.navigate(routes.userHome);
        break;
      case educatorRole:
        action.payload.navigate(routes.educatorHome);
        break;
    }
  } else if (user.role.length > 1) {
    const educatorAccessToken = data.educatorAccessToken;
    const userAccessToken = data.userAccessToken;
    const refreshToken = data.refreshToken;
    StorageService.setStringItem("educatorAccessToken", educatorAccessToken);
    StorageService.setStringItem("userAccessToken", userAccessToken);
    StorageService.setStringItem("refreshToken", refreshToken);
    action.payload.navigate(routes.chooseRole);
  }
}

function* handleChooseRole(action: ChooseRoleActionType) {
  const userAccessToken = StorageService.getStringItem("userAccessToken");
  const educatorAccessToken = StorageService.getStringItem(
    "educatorAccessToken"
  );
  if (!userAccessToken || !educatorAccessToken) {
    action.payload.navigate(routes.login);
    return;
  }
  switch (action.payload.role) {
    case userRole:
      StorageService.setStringItem("accessToken", userAccessToken);
      StorageService.setStringItem("role", action.payload.role);
      action.payload.navigate(routes.userHome);
      break;
    case educatorRole:
      StorageService.setStringItem("accessToken", educatorAccessToken);
      StorageService.setStringItem("role", action.payload.role);
      action.payload.navigate(routes.educatorHome);
  }
}

function* handleSwitchRole(action: any) {
  const role = StorageService.getStringItem("role");
  const educatorAccessToken = StorageService.getStringItem(
    "educatorAccessToken"
  );
  const userAccessToken = StorageService.getStringItem("userAccessToken");
  if (!educatorAccessToken || !userAccessToken || !role) {
    return;
  }
  switch (role) {
    case userRole:
      StorageService.setStringItem("role", educatorRole);
      StorageService.setStringItem("accessToken", educatorAccessToken);
      action.payload.navigate(routes.educatorHome);
      break;
    case educatorRole:
      StorageService.setStringItem("role", userRole);
      StorageService.setStringItem("accessToken", userAccessToken);
      action.payload.navigate(routes.userHome);
  }
}

function* handleRegister(action: RegisterActionType) {
  const { data }: AxiosResponse = yield call(
    registerService,
    action.payload.user
  );

  if (data) {
    action.payload.navigate(routes.login);
  }
}

function* handleResetPassword(action: ResetPasswordActionType) {
  const { token, email, password, navigate } = action.payload;
  const { data }: AxiosResponse = yield call(resetPasswordService, {
    token,
    email,
    password,
  });
  if (data) {
    navigate(routes.login);
  }
}

function* handleSendResetPassword(action: SendResetPasswordActionType) {
  const { data }: AxiosResponse = yield call(
    sendResetPasswordService,
    action.payload
  );
  if (data) showNotification("success", "Email sent successfully");
}

function* handleGetLoggedUser() {
  const { data }: AxiosResponse = yield call(getLoggedUserService);

  if (data) {
    yield put(setLoggedUser({ user: data }));
  }
}

function* handleUploadInviteUsers(action: any) {
  const { data }: AxiosResponse = yield call(
    uploadInviteUsersService,
    action.payload
  );
  if (data) {
    showNotification("success", "Users uploaded successfully");
  }
}
