import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const addLessonService = async (lessonData: FormData) =>
  handleApiCall(() => ApiService.post(apiRoutes.lesson, lessonData));

export const getLessonService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.lesson));

export const getLessonByIdService = async (id: string) =>
  handleApiCall(() => ApiService.get(apiRoutes.lessonById.replace(":id", id)));

export const deleteLessonByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.lessonById.replace(":id", id))
  );

export const editLessonService = async (id: string, lessonData: FormData) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.lessonById.replace(":id", id), lessonData)
  );
