import { Outlet, useLocation } from "react-router-dom";
import { Col, Row } from "antd";
import logoImage from "../../assets/images/company-logo.webp";
import { useEffect, useState } from "react";
import StorageService from "../../services/storageService";
import AppDownload from "../../modules/auth/AppDownload";

const AuthenticationLayout = () => {
  const location = useLocation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    clearTokens();
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const clearTokens = () => {
    StorageService.clearStorage();
  };

  if (screenWidth < 768) {
    switch (location.pathname) {
      case "/register":
        return (
          <Row className="bg-neutral-100 h-screen flex items-center justify-center p-5">
            <Outlet />
          </Row>
        );
      default:
        return <AppDownload />;
    }
  }

  if (screenWidth < 768 && location.pathname === "/register") {
    return <AppDownload />;
  }

  return (
    <Row className="bg-neutral-100">
      <Col span={12} className="flex items-center justify-center">
        <Outlet />
      </Col>
      <Col
        span={12}
        className="bg-white h-screen flex justify-center items-center"
      >
        <img src={logoImage} alt="auth-image" />
      </Col>
    </Row>
  );
};

export default AuthenticationLayout;
